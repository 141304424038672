import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getAllAssignProjectToPeopleAction } from '../../actions/assignProjectToPeople';
import { getExistingProjectAction,updateLeadStatusAction } from '../../actions/project';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { appConstants, isAllowed, moduleConstants } from '../../_constants';
import { useAuth } from '../Auth/Auth';
import TitleComponent from '../Common/TitleComponent';
import DetailsRightSection from '../Layout/DetailsRightSection';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import loaderImage from '../static/images/loading_i.gif';
import PrintThisLead from './print-this-lead';
import LeadNotFound from './lead-not-found';
import { AddLeadModal } from '../../modals/add-lead-modal';
import { PromoteToBiddingModal } from '../../modals/promote-to-bidding-modal';
import { AddProjectModal } from '../../modals/add-project-modal';
import LeadDatesAndDollarsComponent from './ProjectDetailComponent/LeadDatesAndDollars.component';
import LeadHeaderComponent from './ProjectDetailComponent/LeadHeader.component.jsx';
import SiteInfo from './ProjectDetailComponent/SiteInfo';
import ProjectTeam from './ProjectDetailComponent/ProjectTeam';
import InternalProjectTeam from './ProjectDetailComponent/InternalProjectTeam';
import UpdateProjectStatusModal from '../Common/UpdateProjectStatusModal/UpdateProjectStatusModal';

const ViewLeadDetails = (props) => {
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [currentProjectStatus, setCurrentProjectStatus] = useState({});
  const [projectData, setProjectData] = useState({});
  const [allProjectStatus, setAllProjectStatus] = useState([]);
  const [changeLeadToBiddingModal, setChangeLeadToBiddingModal] = useState(false);
  const [isProjectUpdated, setIsProjectUpdated] = useState(false);
  const [isProjectNotFound, setIsProjectNotFound] = useState(false);
  const [initialSection, setInitialSection] = useState('');
  const [addProjectModalOpened, setAddProjectModalOpened] = useState(false);
  const [promotionData, setPromotionData] = useState(null);

  const componentRef = useRef();
  const userId = localStorage.getItem('userId');
  const accountId = localStorage.getItem('accountId');

  useEffect(() => {
    const objectId = getObjectId();
    if (objectId) {
      props.getExistingProjectAction({ projectId: objectId });
      props.getAllJobStatusAction({
        className: 'jobStatusCodes',
        accountId: accountId,
      });
      props.getAllAssignProjectToPeopleAction({
        projectId: objectId,
        accountId: accountId,
      });
    }
  }, []);

  useEffect(() => {
    if (props.projectData?.isProjectNotFound === true) {
      setIsProjectNotFound(true);
    }

    if (
      props.projectData?.objectId &&
      props.projectData.jobStatusCodesCode !== appConstants.jobStatusCode.L &&
      props.projectData.jobStatusCodesCode !== appConstants.jobStatusCode.NP
    ) {
      props.history.replace(
        props.location.pathname.replace('lead-details', 'project-details')
      );
    }

    if (props.isProjectUpdated !== isProjectUpdated && props.isProjectUpdated) {
      props.getExistingProjectAction({ projectId: projectId });
      props.getAllAssignProjectToPeopleAction({
        projectId: projectId,
        accountId: accountId,
      });
      setIsProjectUpdated(props.isProjectUpdated);
    }

    

    setAllProjectStatus(props.allProjectStatus?.allJobStatus || []);
    setProjectData(props.projectData || {});

    if (props.projectData && props.allProjectStatus?.allUsedJobStatus?.length > 0) {
      const currentStatus = props.allProjectStatus.allUsedJobStatus.find(
        (item) => props.projectData.jobStatusCodes === item.objectId
      );

      if (currentStatus) {
        setCurrentProjectStatus(currentStatus);
      }
    }
  }, [props]);

  const currentJobStatusCode = props?.projectData?.jobStatusCodesCode;

  const getObjectId = () => {
    const { match, location } = props;
    let newObjectId = '';
    if (location.state && location.state.row) {
      newObjectId = location.state.row.objectId;
    }
    if (match.params && match.params.objectId) {
      newObjectId = match.params.objectId;
    }
    setProjectId(newObjectId);
    return newObjectId;
  };

  const handleAddLeadModalToggle = () => setIsAddLeadModalOpen(!isAddLeadModalOpen);

  const onOpenEditModal = (section) => {
    setIsAddLeadModalOpen(true);
    setInitialSection(section);
  };

  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);

  const toggleUpdateStatusModal = () => setIsUpdateStatusModalOpen(!isUpdateStatusModalOpen);

  const changeLeadToBidding = () => setChangeLeadToBiddingModal(!changeLeadToBiddingModal);

  const [updateStatusType, setUpdateStatusType] = useState('');
  const changeLeadToNoBid = () => {
    setUpdateStatusType('NP');
    toggleUpdateStatusModal();
  };

  const changeNoBidToLead = () => {
    setUpdateStatusType('L');
    toggleUpdateStatusModal();
  };


  if (isProjectNotFound) {
    return <LeadNotFound />;
  }

  const isJobStatusExist = !!projectData?.jobStatusCodesName;
  const isJobStatusLead =
    isJobStatusExist && projectData.jobStatusCodesCode === appConstants.jobStatusCode.L;
  const isJobStatusNoPursue =
    isJobStatusExist && projectData.jobStatusCodesCode === appConstants.jobStatusCode.NP;

  const canPromote =
    isAllowed(moduleConstants.B, 'assigned', projectData?.userAssignData) ||
    isAllowed(moduleConstants.P1, 'assigned', projectData?.userAssignData) ||
    isAllowed(moduleConstants.P2, 'assigned', projectData?.userAssignData) ||
    isAllowed(moduleConstants.P3, 'assigned', projectData?.userAssignData);

  const isCreator = projectData?.createdBy?.objectId === userId;
  const canEditDetails = isAllowed(
    moduleConstants.EDITPROJDETAILS,
    isCreator ? 'anyAssigned' : 'assigned',
    projectData?.userAssignData
  );

  return (
    <div className='ContactListReport'>
      <TitleComponent
        data={{
          title: `Lead Details - ${projectData?.jobName || 'NA'}`,
        }}
      />
      <Header props={props} />
      <div className='detailsPage'>
        {props.fetchRequest ? (
          <div className='loading_bg'>
            <img className='ajax-loader' src={loaderImage} width='100' height='100' />
          </div>
        ) : null}
        <div className='container-xxl'>
          <div className='row mb-3 mb-md-0'>
            <div className='col-md-6 mb-3 mb-md-0'>
              <div className='p-1'>
                <div className='heading2 mb-1'>{projectData?.jobName || 'NA'}</div>
                <div className='profileSubInfo d-flex flex-wrap '>
                  <div className='profileStatus p-1'>
                    <strong>Status:&nbsp;</strong>
                    {currentProjectStatus?.jobStatusCodesName || 'NA'}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='d-flex mt-md-3 mt-sm-0 flex-wrap justify-content-end gap-2 detailMenu'>
                {isJobStatusExist && canPromote && (
                  <button onClick={changeLeadToBidding} className='btn'>
                    Promote to Bidding
                  </button>
                )}
                {isJobStatusLead &&
                  isAllowed(moduleConstants.L, 'assigned', projectData?.userAssignData) && (
                    <button onClick={changeLeadToNoBid} className='btn'>
                      Mark Not Pursued
                    </button>
                  )}
                {isJobStatusNoPursue &&
                  isAllowed(moduleConstants.L, 'assigned', projectData?.userAssignData) && (
                    <button onClick={changeNoBidToLead} className='btn'>
                      Mark Lead
                    </button>
                  )}
                {canEditDetails && (
                  <button className='btn' onClick={() => onOpenEditModal('')}>
                    Edit project
                  </button>
                )}
                {isJobStatusExist && (
                  <div className='dropdown px-2 detailMenu'>
                    <a
                      className='dropdown-toggle btn'
                      data-bs-toggle='dropdown'
                      href='#'
                      role='button'
                      aria-expanded='false'
                    >
                      <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                    </a>
                    <ul className='dropdown-menu ml-auto'>
                      <li className='dropdown-item'>
                        <ReactToPrint
                          trigger={() => <a>Print Lead Page</a>}
                          content={() => componentRef.current}
                        />
                      </li>
                    </ul>
                  </div>
                )}
                {projectData && (
                  <PrintThisLead
                    ref={componentRef}
                    props={props}
                    useAuth={useAuth}
                    leadPrint={null}
                    statusType={null}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-7 col-xl-7'>
              <div className='projectDetailSidebar'>
                <LeadHeaderComponent
                  useAuth={useAuth}
                  projectData={projectData}
                  onOpenEditModal={onOpenEditModal}
                />
                <SiteInfo
                  useAuth={useAuth}
                  projectStage={'Lead'}
                  projectData={projectData}
                  onOpenEditModal={onOpenEditModal}
                />
                <LeadDatesAndDollarsComponent
                  useAuth={useAuth}
                  projectData={projectData}
                  onOpenEditModal={onOpenEditModal}
                />
                <InternalProjectTeam
                  useAuth={useAuth}
                  projectData={projectData}
                  props={props}
                  isLead
                  onOpenEditModal={onOpenEditModal}
                />
                <ProjectTeam
                  useAuth={useAuth}
                  projectData={projectData}
                  props={props}
                  isLead={true}
                  onOpenEditModal={onOpenEditModal}
                />
              </div>
            </div>
            <div className='col-md-6 col-lg-5 col-xl-5'>
        <div className='projectTabsBox'>
          {projectData && projectData.objectId && (
            <DetailsRightSection
              typeRef={'PROJECT'}
              typeId={projectData.objectId}
              data={projectData}
            />
          )}
        </div>
      </div>
    </div>
  </div>
</div>
<Footer />

<UpdateProjectStatusModal
        isOpen={isUpdateStatusModalOpen}
        toggle={toggleUpdateStatusModal}
        projectId={projectId}
        currentStatus={currentJobStatusCode}
        actionType={updateStatusType}
        projectData={projectData}
        onAfterUpdate={()=>{
          props.getExistingProjectAction({ projectId: projectId });
          props.getAllAssignProjectToPeopleAction({
            projectId: projectId,
            accountId: accountId,
          });
        }}
      />


{isAddLeadModalOpen && (
  <AddLeadModal
    open={isAddLeadModalOpen}
    onClose={handleAddLeadModalToggle}
    projectId={props.match.params.objectId}
    initialSection={initialSection}
  />
)}

<PromoteToBiddingModal
  open={changeLeadToBiddingModal}
  onClose={changeLeadToBidding}
  projectId={props.match.params.objectId}
  allProjectStatus={allProjectStatus}
  onSubmit={(data) => {
    setPromotionData(data);
    setAddProjectModalOpened(true);
  }}
/>

<AddProjectModal
  open={addProjectModalOpened}
  onClose={() => setAddProjectModalOpened(false)}
  projectId={props.match.params.objectId}
  allProjectStatus={allProjectStatus}
  promotionData={promotionData}
  onPromote={() => {
    props.history.push(
      props.location.pathname.replace('lead-details', 'project-details')
    );
  }}
/>
</div>
);
};

const mapStateToProps = (state) => ({
buttonActive: state.project.buttonActive,
projectData: state.project.projectData,
isUpdateStatus: state.project.isUpdateStatus,
allProjectStatus: state.jobStatusCode,
allJobStatusCodes: state.jobStatusCode.allJobStatus,
fetchRequest: state.project.fetchRequest,
isProjectUpdated: state.project.isProjectUpdated,
isProjectNotFound: state.project.projectData?.isProjectNotFound,
});

export default withRouter(
connect(mapStateToProps, {
getExistingProjectAction,
getAllJobStatusAction,
getAllAssignProjectToPeopleAction,
})(ViewLeadDetails)
);