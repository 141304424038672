import { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getTimeLineAction } from '../../actions/message';
import { EmailView, NoteView, ScheduleView, ProjectActivityView } from './RightSectionRender';
import { LightBoxComponent } from './LightBox.component';
import styles from './right-section.module.scss';
import ActivityHeader from './ActivityHeader.component';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';

const TimeLineTab = (props) => {
  const [timelineData, setTimeLineData] = useState([]);
  const [typeId, setTypeId] = useState('');
  const [typeRef, setTypeRef] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [attachmentItems, setAttachmentItems] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [filterType, setFilterType] = useState(['all']);
  const [sortDirection, setSortDirection] = useState('desc');

  const isProjectType = typeRef === 'PROJECT';
  const isContactType = typeRef === 'CONTACT';
  const isCompanyType = typeRef === 'COMPANY';

  const projectFilters = [
    {
      title: 'All',
      key: 'all',
      children: [
        {
          title: 'Activity',
          key: 'activity',
        },
        {
          title: 'Notes',
          key: 'note',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: 'Events',
          key: 'event',
        },
      ],
    },
  ];

  const filterOptions = [
    {
      title: 'All',
      key: 'all',
      children: [
        {
          title: 'Notes',
          key: 'note',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: 'Events',
          key: 'event',
        },
      ],
    },
  ];

  const companyFilterOptions = [
    {
      title: 'All company items',
      key: 'all',
      children: [
        {
          title: 'Notes',
          key: 'note',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: 'Events',
          key: 'event',
        },
      ],
    },
    {
      title: 'Include employee notes',
      key: 'subNotes',
    }
  ];


  useEffect(() => {
    if (
      !props.isSave &&
      props.timelineData &&
      props.timelineData?.length >= 0 &&
      props.timelineDataFetched
    ) {
      setTimeLineData(props.timelineData);
      setIsLoading(false);
    }
  }, [props.isSave, props.timelineData, props.allMessages, props.allEmails, props.allSchedule]);

  useEffect(() => {
    setTypeId(props.typeId);
    setTypeRef(props.typeRef);
    setUpdatedAt(props.updatedAt);
  }, [props.typeId, props.typeRef, props.updatedAt]);

  useEffect(() => {
    getTimelineData();
  }, [typeId, typeRef, updatedAt]);

  const [isLoading, setIsLoading] = useState(true);

  const getTimelineData = () => {
    if (typeId?.length > 0) {
      const data = {
        typeId,
        typeRef,
        page: 0,
        displayLimit: 99999999,
      };
      props.getTimeLineAction(data);
      setIsLoading(true);
    }
  };

  const formatEmailAttachments = (selectedEmail) => {
    return selectedEmail?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Email\n' +
        'to: ' +
        selectedEmail.emailTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedEmail.emailSub +
        '\n' +
        selectedEmail.emailBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const formatNoteAttachments = (selectedNote) => {
    return selectedNote?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        selectedNote.title + '\n' + selectedNote.description?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  useEffect(() => {
    if (!lightBoxOpen) {
      setAttachmentIndex(null);
      setSelectedItem(null);
    }
  }, [lightBoxOpen]);

  const showHideLightBox = (item, index) => {
    if (!item) {
      setLightBoxOpen(false);
      return;
    }

    setSelectedItem(item);
    setAttachmentIndex(index);

    if (item.classType === 'cppEmails') {
      setAttachmentItems(formatEmailAttachments(item));
    } else if (item.classType === 'cppNotes') {
      setAttachmentItems(formatNoteAttachments(item));
    }

    setLightBoxOpen(!lightBoxOpen);
  };

  const sortTimelineItems = (items, direction) => {
    // Helper function to sort items
    const sortItems = (a, b) => {
      if (a.isPinned !== b.isPinned) return b.isPinned ? 1 : -1;
      if (a.isPinned && b.isPinned) return new Date(b.pinDate) - new Date(a.pinDate);
      const dateComparison = new Date(b.createdAt) - new Date(a.createdAt);
      return direction === 'asc' ? -dateComparison : dateComparison;
    };

    // Group items by threads
    const threadMap = new Map();
    const rootItems = [];

    items.forEach((item) => {
      if (item.replyTo) {
        if (!threadMap.has(item.replyTo)) {
          threadMap.set(item.replyTo, []);
        }
        threadMap.get(item.replyTo).push(item);
      } else {
        rootItems.push(item);
      }
    });

    // Sort root items
    rootItems.sort(sortItems);

    // Function to recursively sort and flatten threads
    const sortAndFlattenThread = (item) => {
      const result = [item];
      if (threadMap.has(item.objectId)) {
        const replies = threadMap.get(item.objectId);
        replies.sort(sortItems);
        replies.forEach((reply) => {
          result.push(...sortAndFlattenThread(reply));
        });
      }
      return result;
    };

    // Sort and flatten all threads
    const sortedItems = rootItems.map((item) => sortAndFlattenThread(item)).flat();

    return sortedItems;
  };

  const [filteredData, setFilteredData] = useState([]);

  const pathnameMap = new Map()
    .set('CONTACT', '/contact-details/')
    .set('LEAD', '/lead-details/')
    .set('PROJECT', '/project-details/')
    .set('COMPANY', '/company-details/')
    .set('USER', '/contact-details/');

  const thisURL = pathnameMap.get(typeRef) + typeId;

  const canFollowUp = location.pathname.includes('notes/followUp') && false;

  const defaultFilter = ['all'];

  const handleSearch = (value) => {
    setFilterText(value);
  };

  const handleSort = (direction) => {
    setSortDirection(direction);
    // Apply sorting logic here
  };

  const handleFilter = (filters) => {
    setFilterType(filters);
  };

  useEffect(() => {
    applyFilters();
  }, [timelineData, filterText, filterType, sortDirection]);

  const applyFilters = () => {
    if (!timelineData) return;
    let filtered = timelineData.filter((item) => {
      const matchesText =
        !(filterText?.length > 0) ||
        true ||
        item?.title?.toLowerCase().includes(filterText.toLowerCase()) ||
        item?.description?.toLowerCase().includes(filterText.toLowerCase());
      const matchesType =
        (filterType.includes('all')&&!item.originName) || // all original items
        (filterType.includes('subNotes') && item.originName) || // all replies
        (item.classType && filterType.includes(item.classType.toLowerCase().replace('cpp', ''))) ||
        filterType.includes(item.itemType);
      return matchesText && matchesType;
    });

    filtered = sortTimelineItems(filtered, sortDirection);
    setFilteredData(filtered);
  };

  const renderTimelineData = (timelineDataContent) => {
    if (timelineDataContent !== undefined) {
      return timelineDataContent.map((v) => {
        if (v.classType === 'cppEmails')
          return (
            <EmailView
              emailData={v}
              showHideLightBox={showHideLightBox}
              filterText={filterText}
            ></EmailView>
          );
        if (v.itemType === 'note') {
          return (
            <>
              <NoteView
                noteData={v}
                showHideLightBox={showHideLightBox}
                filterText={filterText}
              ></NoteView>

              {v?.replies?.map((reply) => (
                <NoteView
                  noteData={reply}
                  showHideLightBox={showHideLightBox}
                  filterText={filterText}
                  isReply
                ></NoteView>
              ))}
            </>
          );
        }
        if (v.classType === 'cppSchedule')
          return (
            <ScheduleView
              scheduleData={v}
              showHideLightBox={showHideLightBox}
              filterText={filterText}
            ></ScheduleView>
          );
        if (v.eventChangeByUser)
          return (
            <ProjectActivityView activityData={v} filterText={filterText}></ProjectActivityView>
          );
      });
    }
  };

  useEffect(() => {
    // Apply filter logic here
  }, [filterType]);

  const handleAddNew = () => {
    // Open dropdown menu for adding new items
  };

  const additionalButtons = [
    <Link
      key='add-dropdown'
      className='btn btn-outline-secondary dropdown-toggle p-1 pe-2 ps-2'
      to='#'
      role='button'
      data-bs-toggle='dropdown'
      aria-expanded='false'
    >
      Add
    </Link>,
    <ul key='add-dropdown-menu' className='dropdown-menu dropdown-menu-end'>
      <li>
        <Link className='dropdown-item' to={`${thisURL}/notes/add`}>
          Add Note
        </Link>
      </li>
      <li>
        <Link className='dropdown-item' to={`${thisURL}/email/new`}>
          Send Email
        </Link>
      </li>
      <li>
        <Link className='dropdown-item' to={`${thisURL}/schedule/add`}>
          Add Event
        </Link>
      </li>
    </ul>,
  ];

  return (
    <>
      <div className='container'>
        <div className='row pt-3 pb-2'>
          <div className='col ps-0 pe-2'>
            <ActivityHeader
              onSearch={handleSearch}
              onSort={handleSort}
              onFilter={handleFilter}
              onAddNew={handleAddNew}
              canFilter={true}
              defaultFilter={defaultFilter}
              filterOptions={isProjectType ? projectFilters : isCompanyType? companyFilterOptions: filterOptions}
              sortDirection={sortDirection}
              additionalButtons={additionalButtons}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      ) : (
        <Scrollbars style={{ height: 'calc(100vh)', minHeight: '500px' }}>
          {filteredData && filteredData.length === 0 ? (
            <div className='p-2 ps-4'>No activities found</div>
          ) : (
            renderTimelineData(filteredData)
          )}
        </Scrollbars>
      )}
      <LightBoxComponent
        attachments={attachmentItems ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
    </>
  );
};

function mapStateToProps(state) {
  let { message } = state;
  let { timelineData, timelineDataFetched } = message;
  return {
    timelineData: timelineData && timelineData.length ? timelineData : [],
    timelineDataFetched: timelineDataFetched,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getTimeLineAction,
  })(TimeLineTab)
);
