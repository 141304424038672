import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getLastJobNumAction } from '../../actions/project';
import { getNextProjectNumber, getUpdateProjectForm, validateProjectNumber } from '../../modules/projects';
import { selectGetNextProjectNumber } from '../../modules/projects/selectors';
import { getJobStatusCodesSettings } from '../../modules/settings';
import { selectGetJobStatusCodesSettings } from '../../modules/settings/selectors';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { selectLastJobData, selectProjectData } from '../../selectors/project';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { useDebounce } from '../../shared/hooks/use-debounce';
import { useSubmit } from '../../shared/hooks/use-submit';
import { isAllowed, moduleConstants } from '../../_constants';
import  styles from '../../components/Common/UpdateProjectStatusModal/UpdateProjectStatusModal.module.scss';
import { renderHeader } from '../../components/Common/UpdateProjectStatusModal/UpdateProjectStatusModal';

type PromoteToBiddingModalProps = {
  open: boolean;
  onClose: () => unknown;
  projectId: string;
  onSubmit?: (...args: any[]) => any;
};

export const PromoteToBiddingModal = ({
  open,
  onClose,
  projectId,
  onSubmit: onSubmitted = (data?: any) => Promise.resolve(data),
}: PromoteToBiddingModalProps) => {
  const dispatch = useAppDispatch();
  const projectData = useAppSelector(selectProjectData);
  const lastJobData = useAppSelector(selectLastJobData);

  const accountInfo = useAppSelector(selectGeneralInfo);
  const [nextJobNumPre, setNextJobNumPre] = useState<string>();
  const [nextJobNum, setNextJobNum] = useState<string>();
  const [nextJobNumError, setNextJobNumError] = useState<string>('');

  const { data: nextProjectNumber } = useAppSelector(selectGetNextProjectNumber);
  const { data: statusCodes } = useAppSelector(selectGetJobStatusCodesSettings);

  const form = useForm({
    defaultValues: {
      jobStatusCodesId: {value: '', label: ''},
      updateComment: '',
    },
  });

  const { control, watch } = form;

  const accountId = localStorage.getItem('accountId');

  const permissionsMap = {
    B: moduleConstants.B,
    P1: moduleConstants.P1,
    P2: moduleConstants.P2,
    P3: moduleConstants.P3,
  };

  useEffect(() => {
    if (!open) return;

    const allowedJobStatusCodesCode = Object.entries(permissionsMap)
      .filter(([, moduleName]) => isAllowed(moduleName, 'assigned', projectData?.userAssignData))
      .map(([name]) => name);

    dispatch(getLastJobNumAction({ accountId }));
    dispatch(getNextProjectNumber());
    dispatch(
      getJobStatusCodesSettings({
        jobStatusCodesCode: allowedJobStatusCodesCode,
        includeDisabled: false,
      })
    )
      .unwrap()
      .then((data) => {
        const biddingJob = data.find((item) => item.jobStatusCodesCode === 'B');
        if(biddingJob){
          form.setValue('jobStatusCodesId', { value: biddingJob._id, label: biddingJob.jobStatusCodesName } as any);
        }
      });
  }, [open]);

  const [onSubmit] = useSubmit(async (directSubmit = true) => {
    const jobStatusCodesId = form.getValues('jobStatusCodesId');
    const selectedJobStatusCodesCode =
      statusCodes.find((jsc) => jsc._id === jobStatusCodesId?.value as string)?.jobStatusCodesCode || '';
    const moduleName = permissionsMap[selectedJobStatusCodesCode] || '';
    const data = {
      projectId,
      jobNum: nextJobNum,
      jobNumPrefix: nextJobNumPre,
      jobStatusCodesId: jobStatusCodesId?.value as string,
      moduleName,
      updateComment: form.getValues('updateComment'),
      directSubmit,
    };

    onSubmitted(data);
    onClose();
  }, [{ projectNumber: { jobNum: nextJobNum, jobNumPrefix: nextJobNumPre } }, projectId]);

  const [jobName, setJobName] = useState<string>('');
  useEffect(() => {
    if(!open) return;
    if (projectData?.jobName) {
      setJobName(projectData.jobName);
    }
    if ((projectData?.objectId !== projectId) && projectId && !(projectData?.jobName)) {
      dispatch(getUpdateProjectForm({ projectId }))
        .unwrap()
        .then((data) => {
          setJobName(data.jobName);
        }
        );
    }
  }, [projectData?.jobName, projectId, open]);


  useEffect(() => {
    setNextJobNum(nextProjectNumber?.jobNum.toString());
    setNextJobNumPre(nextProjectNumber?.jobNumPrefix);
  }, [nextProjectNumber]);

  useDebounce(async () => {
    if (!nextJobNumPre || !nextJobNum) return;

    const data = await dispatch(
      validateProjectNumber({ projectNumber: { jobNum: nextJobNum, jobNumPrefix: nextJobNumPre } })
    ).unwrap();

    const { valid, message } = data as any;

    setNextJobNumError(valid ? '' : message);
  }, [nextJobNumPre, nextJobNum]);

  const handleChangeJobNumber = (e) => {
    const letters = /^[0-9]+$/;
    if (
      e.target.name === 'nextJobNum' &&
      e.target.value.length <= 4 &&
      e.target.value.match(letters)
    ) {
      setNextJobNum(e.target.value);
    }
    if (e.target.name === 'nextJobNumPre' && e.target.value.length <= 4) {
      setNextJobNumPre(e.target.value);
    }
  };

 // const [dropdownOpen, setDropdownOpen] = useState(false);
//  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

const canAssignJobNumber = (accountInfo?.sequentialJobNumber) === false;
const [modalTitle, setModalTitle] = useState<string>('');

const currentJobStatusId = watch('jobStatusCodesId');

useEffect(() => {
  setModalTitle('Update status to "'+statusCodes.find((jsc) => jsc._id === currentJobStatusId?.value)?.jobStatusCodesName+'"');
}, [currentJobStatusId]);

  return (
    <Modal
      backdrop='static'
      keyboard={true}
      isOpen={open}
      toggle={onClose}
      id='projectStatusUpdatePopup'
      size='lg'
    >
      <ModalHeader toggle={onClose}>{modalTitle}</ModalHeader>
      <ModalBody>
        <div className='modalBody'>
          <div className='container-md'>
            {renderHeader({jobName: jobName, jobNum: projectData?.jobNum})}
           
            <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                Select Status:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='jobStatusCodesId'
                    control={control}
                    rules={{ required: 'Action is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        formatOptionLabel={(option) => option.label}
                        options={statusCodes.map((item) => ({
                          value: item._id,
                          label: item.jobStatusCodesName,
                        }))}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>
            <div className='row'>
              <div className='col-md-12'>
                <div>
                  <label data-testid='last-job-num-text'>
                    The Last Job Number assigned was:{!canAssignJobNumber?' ':<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                      {lastJobData?.initialJobNumberPre
                        ? lastJobData?.initialJobNumberPre + '-'
                        : '#'}
                      {lastJobData?.previousCountNumber?.toString() || 'NA'}
                    
                  </label>{'. '}
                 
                 {canAssignJobNumber && (
                  <br/>)}
                 
                  <label data-testid='next-job-num-text'>
                    This Lead will be assigned Job Number:{' '}
                    {canAssignJobNumber ? (
                      <span>
                        <input
                          type='text'
                          name='nextJobNumPre'
                          className={styles.jobNumberInput}
                          onChange={handleChangeJobNumber}
                          value={nextJobNumPre}
                          placeholder='Prefix'
                          disabled={
                            !canAssignJobNumber
                          }
                        />
                        <input
                          type='number'
                          name='nextJobNum'
                          className={styles.jobNumberInput}
                          onChange={handleChangeJobNumber}
                          value={nextJobNum}
                          placeholder='Job Number'
                          disabled={
                            !canAssignJobNumber
                          }
                        />{' '}
                        {nextJobNumError && <span className='error'>{nextJobNumError}</span>}
                      </span>
                    ) : (
                      <strong>
                        {nextJobNumPre || ''}-{String(nextJobNum).padStart(4, '0') || '0001'}
                      </strong>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div className='row'>
            <div className='form-group mb-3 row pe-0'>
                <label className={`col-sm-3 col-form-label pe-0 ${styles.updateFieldLabel}`}>Comment: </label>
                <div className='col-sm-9 pe-0'>
                  <Controller
                    name='updateComment'
                    control={control}
                    render={({ field }) => <textarea {...field} className='form-control' rows={3} />}
                  />
                </div>
              </div>
            </div>
            <div className='clearfix'></div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='d-flex ms-auto justify-content-end p-2'>
          <Button color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <div className='btn-group ms-2'>
            <button
              className='btn btn-primary'
              onClick={() => onSubmit(true)}
            >
              Update
            </button>
            <button
              type='button'
              className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <span className='visually-hidden'>Toggle Dropdown</span>
            </button>
            <ul className='dropdown-menu'>
              <li>
                <button
                  className='dropdown-item'
                  onClick={() => onSubmit(false)}
                >
                  Update & edit project
                </button>
              </li>
            </ul>
          </div>

        </div>
      </ModalFooter>
    </Modal>
  );
};
