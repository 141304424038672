import React, { useState, useEffect, useRef, Suspense, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';
import { selectAllUsedJobStatusOptions } from '../../../selectors/jobStatusCode';
import { getExistingProjectAction } from '../../../actions/project';
import { getAllAssignProjectToPeopleAction } from '../../../actions/assignProjectToPeople';
import { AddProjectModal } from '../../../modals/add-project-modal';
import { selectGeneralInfo } from '../../../selectors/accountSettings';
import { isAllowed, moduleConstants, reportAccess } from '../../../_constants';
import ProjectStatusModal from '../../Common/ProjectStatusModal';
import TitleComponent from '../../Common/TitleComponent';
import { ChangeOrderModal } from '../../../modals/change-order-modal';
import { BidModal } from '../../../modals/bid-modal/bid-modal';
import { SummaryFinancial } from '../../../modals/summary-financial-modal';
import { ChangeOrderIntermediateModal } from '../../../modals/change-order-intermediate-modal';
import { FillTemplateModal } from '../../Layout/TemplateFiller/FillTemplateModal';
import { ManageContractModal } from '../../../modals/manage-contract-modal';
import { ProgressSuggestionsModal } from '../../../modals/progress-suggestions-modal';
import { Checkbox } from 'antd';

const ProjectDetailToolbar = forwardRef(({ projectId, projectData, componentToPrint, hideFieldsCallback }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Refs
  const addProjectModalRef = useRef(null);
  const projectStatusModalRef = useRef(null);
  useImperativeHandle(ref, () => ({
    onEditProjectClick(initialSection) {
      onEditProjectClick(initialSection);
    },
    onManageBidModal(viewType) {
      onManageBidModal(viewType);
    },
    onManageContractModal() {
      toggleManageContract();
    },
    onUpdateWIPModal() {
      onUpdateWIPModal();
    }
  }));

  const accountId = localStorage.getItem('accountId');
  const userId = localStorage.getItem('userId');

  const projectDataLoaded = projectData?.objectId ? true : false;


  useEffect(() => {
    dispatch(getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: accountId,
    }));

    return () => {
      if (projectStatusModalRef.current) {
        projectStatusModalRef.current = undefined;
      }
    };
  }, [dispatch, accountId]);

  const accountInfo = useSelector(selectGeneralInfo);
  const allJobStatusOptions = useAppSelector(selectAllUsedJobStatusOptions);

  const [currentJobStatusCode, setCurrentJobStatusCode] = useState('');
  const [currentJobStatusName, setCurrentJobStatusName] = useState('NA');
  const [currentJobStatus, setCurrentJobStatus] = useState({});
  const [userAssignData, setUserAssignData] = useState([]);
  const [currentChangeOrderId, setCurrentChangeOrderId] = useState(null);

  const [isCreator, setIsCreator] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts.includes('change-orders') && pathParts.includes('view')) {
      const changeOrderId = pathParts[pathParts.length - 1];
      setCurrentChangeOrderId(changeOrderId);
      setIsChangeOrderIntermediateModalOpen(true);
      setIsChangeOrderModalOpen(true);
    }else if(pathParts.includes('change-orders')){
      setIsChangeOrderIntermediateModalOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (projectData && projectData.jobStatusCodes) {

      const curJobStatus = allJobStatusOptions?.find(
        item => item.value === projectData.jobStatusCodes
      );
      if (curJobStatus) {
        setCurrentJobStatus(curJobStatus);
        setCurrentJobStatusCode(curJobStatus.code);
        setCurrentJobStatusName(curJobStatus.label);
      } else {
        setCurrentJobStatusCode('NA');
        setCurrentJobStatusName('NA');
      }

      if (projectData.createdBy?.objectId === userId)
        setIsCreator(true);
      else
        setIsCreator(false);
    }
  }, [projectData?.jobStatusCodes, allJobStatusOptions]);

  const [currentBidId, setCurrentBidId] = useState('');

  const isBiddingProject = ['B', 'P1', 'P2', 'P3'].includes(currentJobStatusCode);

  const isOnHoldBidding = currentJobStatusCode === 'OH';
  const isOnHoldPreCon = currentJobStatusCode === 'PC';
  const isOnHoldPending = currentJobStatusCode === 'OHP';

  const isOnHold = isOnHoldBidding || isOnHoldPreCon || isOnHoldPending;

  const isSoldNotStartedProject = currentJobStatusCode === 'SNS';
  const isActiveProject = currentJobStatusCode === 'A';
  const isDeliveredProject = currentJobStatusCode === 'D';
  const isSuspendedProject = currentJobStatusCode === 'SUSP';

  const isActiveConstruction = isSoldNotStartedProject | isDeliveredProject | isActiveProject;

  const isBidPendingProject = ['BP', 'P1P', 'P2P', 'P3P'].includes(currentJobStatusCode);

  const isServiceProject = ['SVC', 'SVC-C', 'SVC-D'].includes(currentJobStatusCode);

  const isWarrantyService = isServiceProject && !!projectData.isWarrantyService;

  const isServiceDelivered = currentJobStatusCode === 'SVC-D';
  const isServiceComplete = currentJobStatusCode === 'SVC-C';

  const isPostConstruction = ['W', 'C', 'XT'].includes(currentJobStatusCode);

  const isClosedBiddingProject = ['NC', 'XC', 'R'].includes(currentJobStatusCode);

  const isUndefinedProjectStatus = !(
    isPostConstruction ||
    isBidPendingProject ||
    isBiddingProject ||
    isActiveConstruction ||
    isSuspendedProject ||
    isOnHold
  );

  useEffect(() => {
    if (projectData && projectData.curBidId) {
      setCurrentBidId(projectData.curBidId);
    }
  }, [projectData?.curBidId]);


  const projectHasValidBid = (isBiddingProject || isOnHold || isBidPendingProject) && currentBidId;


  const showChangeOrders =
    isSoldNotStartedProject ||
    isActiveProject ||
    isDeliveredProject ||
    isPostConstruction ||
    isSuspendedProject;

  const openBiddingDialogAs =
    isClosedBiddingProject || isBidPendingProject || isOnHoldPreCon || isOnHoldPending
      ? 'pending'
      : 'createEdit';

  const [hasOverridePermissions, setHasOverridePermissions] = useState(false);
  const [canEditDetails, setCanEditDetails] = useState(false);
  const [canUpdateStatus, setCanUpdateStatus] = useState(false);

  useEffect(() => {
    if (projectData && projectData.userAssignData) {
      setUserAssignData(projectData.userAssignData);

      setHasOverridePermissions(isAllowed(
        moduleConstants.OVERRIDE,
        isCreator ? 'anyAssigned' : 'assigned',
        projectData?.userAssignData
      ));

      setCanEditDetails(isAllowed(
        moduleConstants.EDITPROJDETAILS,
        isCreator ? 'anyAssigned' : 'assigned',
        projectData?.userAssignData
      ));

      if (isActiveProject || isDeliveredProject || isSoldNotStartedProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.A,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isBiddingProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.B,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isBidPendingProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.BP,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isServiceProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.W,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isPostConstruction) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.W,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isSuspendedProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.SUSP,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isOnHold) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.OH,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

      if (isClosedBiddingProject) {
        setCanUpdateStatus(isAllowed(
          moduleConstants.NC,
          isCreator ? 'anyAssigned' : 'assigned',
          projectData?.userAssignData
        ));
      }

    }
  }, [projectData?.userAssignData, currentJobStatusCode]);

  const isAllowedCurrentPermissionGroup = currentJobStatus.permissionsGroupName && isAllowed(
    moduleConstants[currentJobStatus.permissionsGroupName],
    userAssignData?.length ?? 0,
    userAssignData
  );


  const [isFinancialsModalOpen, setIsFinancialsModalOpen] = useState(false);
  const [isStatusUpdateModalOpen, setIsStatusUpdateModalOpen] = useState(false);
  const [isBidManagementModalOpen, setIsBidManagementModalOpen] = useState(false);
  const [isChangeOrderIntermediateModalOpen, setIsChangeOrderIntermediateModalOpen] = useState(false);
  const [isChangeOrderModalOpen, setIsChangeOrderModalOpen] = useState(false);
  const [isSuggestionsModalOpen, setIsSuggestionsModalOpen] = useState(false);
  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);
  const [isPromoteToActiveProcess, setIsPromoteToActiveProcess] = useState(false);
  const [isPromoteToBiddingProcess, setIsPromoteToBiddingProcess] = useState(false);
  const [statusUpdateType, setStatusUpdateType] = useState(null);
  const [isManageContractModalOpen, setManageContractModalOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState({});
  const [suggestedChanges, setSuggestedChanges] = useState({});
  const [editProjectModalOpen, setEditProjectModalOpen] = useState(false);
  const [initialSection, setInitialSection] = useState('');
  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);

  const [hideEmptyFields, setHideEmptyFields] = useState(localStorage.getItem('hideEmptyFields') === 'true');

  const hideEmptyFieldsToggle = (e) => {
    if (e?.target?.checked != undefined) {
      setHideEmptyFields(e.target.checked);

      localStorage.setItem('hideEmptyFields', e.target.checked);
      hideFieldsCallback(e.target.checked);
    } else {
      setHideEmptyFields(!hideEmptyFields);
      localStorage.setItem('hideEmptyFields', !hideEmptyFields);
      hideFieldsCallback(!hideEmptyFields);
    }
  };

  useEffect(() => {
    if (hideEmptyFields) {
      hideFieldsCallback(true);
    } else {
      hideFieldsCallback(false);
    }
  }, [hideEmptyFields]);



  // Toggle functions for modals and other state changes
  const toggleSummaryFinancials = () => {
    setIsFinancialsModalOpen(!isFinancialsModalOpen);
  };

  const toggleStatusUpdate = (updateType, e) => {
    setStatusUpdateType(updateType);
    setIsStatusUpdateModalOpen(!isStatusUpdateModalOpen);
    setIsSuggestionsModalOpen(false);
  };

  const toggleManageContract = () => {
    setManageContractModalOpen(!isManageContractModalOpen);

    if (isManageContractModalOpen) {
      // reset Promote dialog when closing ManageContract
      if (projectStatusModalRef.current && projectStatusModalRef.current.toggleStatusUpdateReset) {
        projectStatusModalRef.current.toggleStatusUpdateReset();
      }
    }
  };

  const handleEditProjectModalToggle = () => {
    setEditProjectModalOpen(!editProjectModalOpen);
  };

  const handleChangeOrderIntermediateModalToggle = (shouldClose) => {
    if(shouldClose){
      setIsChangeOrderIntermediateModalOpen(false);
    }else{
      setIsChangeOrderIntermediateModalOpen(!isChangeOrderIntermediateModalOpen);
    }
    
  };

  const handleChangeOrderModalToggle = () => {
    setIsChangeOrderModalOpen(!isChangeOrderModalOpen);
  };

  const handleBidManagementModalToggle = (viewType, e) => {
    setIsBidManagementModalOpen(!isBidManagementModalOpen);
    setIsPromoteToBiddingProcess(false);
  };

  const handleSuggestionsModalToggle = () => {
    setIsSuggestionsModalOpen(!isSuggestionsModalOpen);
  };

  const onSuggestProgress = (suggestedChanges) => {
    if (!isPromoteToActiveProcess) {
      setSuggestedChanges(suggestedChanges);
      handleSuggestionsModalToggle();
    }
  };

  const onBidManagementModalSubmit = () => {
    addProjectModalRef.current.getProjectData();
  };

  const onAfterPromoteToBidding = () => {

    setIsBidManagementModalOpen(true);
    setIsPromoteToBiddingProcess(true);
    //addProjectModalRef.current.getProjectData();
  };

  const onEditProjectClick = (initialSection = '') => {
    setInitialSection(initialSection);
    handleEditProjectModalToggle();
  };

  const onNextProjectStatusModalClick = () => {
    setIsPromoteToActiveProcess(true);
    projectStatusModalRef.current.toggleModal();
    toggleManageContract();
  };

  const onSubmitManageContract = (contractData) => {
    if (isPromoteToActiveProcess) {
      if (projectStatusModalRef.current && projectStatusModalRef.current.submitUpdateProject) {
        projectData.startDate = contractData.baselineStartDate;
        projectData.endDate = contractData.baselineEndDate;
        projectData.originalContractAmount = contractData.originalContractAmount;
        projectData.originalGrossProfit = contractData.originalGrossProfit;
        projectData.baselineStartDate = contractData.baselineStartDate;
        projectData.baselineEndDate = contractData.baselineEndDate;
        projectData.currentContractAmount = contractData.originalContractAmount;
        projectData.currentGrossProfit = contractData.originalGrossProfit;
        projectStatusModalRef.current.submitUpdateProject({}, projectData, "Approved");
      }
      setIsPromoteToActiveProcess(false);
    }

  };

  const onManageBidModal = (viewType = '') => {
    setIsBidManagementModalOpen(!isBidManagementModalOpen);
  };

  const onDiscardStatusUpdate = () => {
    setStatusUpdateData({});
  };

  const onUpdateWIPModal = (data = {}) => {
    setIsStatusUpdateModalOpen(!isStatusUpdateModalOpen);
    setStatusUpdateType('UpdateWIP');
    setStatusUpdateData({ ...data });
  };

  const onEditProjectModalClose = () => {
    setEditProjectModalOpen(false);
  };

  const onEditProjectModalSubmit = () => {
    dispatch(getExistingProjectAction({ projectId }));
    dispatch(getAllAssignProjectToPeopleAction({ projectId }));
  };

  const toggleFillTemplate = (e) => {
    setFillTemplateOpen(!fillTemplateOpen);
  };


  const isAllowedChangeOrders = isAllowed(
    moduleConstants.CO,
    userAssignData?.length ?? 0,
    userAssignData
  );


  const renderManageBid = () => {
    return (
      <div className='px-0 m-0'>
        {
          // Bidding Menu
          isAllowedCurrentPermissionGroup && (
            (isOnHoldPending || isOnHoldPreCon || isBidPendingProject) ? (
              <button
                className='btn btn-primary'
                onClick={handleBidManagementModalToggle.bind(this, 'pending')}
              >
                {projectHasValidBid ? t('Manage pending bid') : t('Create pending bid')}
              </button>
            ) :

              (isOnHold || isBiddingProject) ? (
                <button
                  className='btn btn-primary'
                  onClick={handleBidManagementModalToggle.bind(this, 'createEdit')}
                >
                  {projectHasValidBid ? t('Manage bid') : t('Enter bid')}
                </button>
              ) : <></>)}
      </div>)
  }


  const renderActiveFinanceButtons = () => {
    return (
      <div>
        {
          // Active Menu
          isAllowedCurrentPermissionGroup &&
          (isActiveProject || isSoldNotStartedProject || isDeliveredProject) && (
            <>
              <li className='btn-group px-2'>
                <a
                  className='dropdown-toggle btn'
                  data-bs-toggle='dropdown'
                  href='#'
                  role='button'
                  aria-expanded='false'
                >
                  {t('Dates & Dollars')}
                </a>
                {/*<button
                          type='button'
                          class='btn'
                          onClick={this.toggleSummaryFinancials.bind(this)}
                        >
                          {t('Dates & Dollars')}
                        </button>
                        <button
                          type='button'
                          class='btn dropdown-toggle dropdown-toggle-split'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span class='visually-hidden'>Toggle Dropdown</span>
                        </button>*/}
                <ul className='dropdown-menu  ml-auto'>
                  {isAllowedChangeOrders &&
                    showChangeOrders && (
                      <li className='dropdown-item'>
                        <a
                          className='statusBtn'
                          data-tooltip-content={t(
                            'Modify original contract dates and values'
                          )}
                          data-tooltip-id='OriginalContract.ToolTip'
                          onClick={toggleManageContract}
                        >
                          {t('Edit original contract')}
                        </a>
                        <Tooltip
                          id='OriginalContract.ToolTip'
                          place='right'
                          delayShow={1000}
                        />
                      </li>
                    )}
                  {isAllowedChangeOrders &&
                    showChangeOrders && (
                      <li className='dropdown-item'>
                        <a
                          className='statusBtn'
                          data-tooltip-content={t(
                            'Manage Change Orders and changes in baseline dates'
                          )}
                          data-tooltip-id='ChangeOrders.ToolTip'
                          onClick={() => handleChangeOrderIntermediateModalToggle()}
                        >
                          {t('Manage change orders')}
                        </a>
                        <Tooltip id='ChangeOrders.ToolTip' place='right' delayShow={1000} />
                      </li>
                    )}
                  {isAllowedChangeOrders &&
                    showChangeOrders && (
                      <li className='dropdown-item'>
                        <a
                          className='statusBtn'
                          data-tooltip-content={t(
                            'Update project progress and Cost-To-Complete'
                          )}
                          data-tooltip-id='SingleWIP.ToolTip'
                          onClick={toggleStatusUpdate.bind(this, 'UpdateWIP')}
                        >
                          {t('Update progress')}
                        </a>
                        <Tooltip id='SingleWIP.ToolTip' place='right' delayShow={1000} />
                      </li>
                    )}
                </ul>
              </li>
            </>
          )
        }
      </div>
    );
  }

  const renderOverflowMenu = () => {
    return (<>
      {projectDataLoaded && (
        <li className='dropdown px-2'>
          <a
            className='dropdown-toggle btn'
            data-bs-toggle='dropdown'
            href='#'
            role='button'
            aria-expanded='false'
          >
            <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
          </a>
          <ul className='dropdown-menu dropdown-menu-end ml-auto'>
            {false && (
              <>
                {isAllowed(reportAccess.PPIR, 'assigned', userAssignData) && (
                  <li className='dropdown-item'>
                    <Link
                      className=''
                      to={{
                        pathname: `/project-prelim-info/${projectId}`,
                        state: {
                          projectData: projectData,
                          objectId: projectId,
                        },
                      }}
                      data-tooltip-content={t('MENU4.SUBMENUS.SUBMENU5.Tooltip')}
                      data-tooltip-id='MENU4.SUBMENUS.SUBMENU5.Tooltip'
                    >
                      {t('Preliminary Info Report')}
                    </Link>
                    <Tooltip
                      id='MENU4.SUBMENUS.SUBMENU5.Tooltip'
                      place='right'
                      delayShow={1000}
                    />
                  </li>
                )}
                {isAllowed(reportAccess.PIHS, 'assigned', userAssignData) && (
                  <li className='dropdown-item'>
                    <Link
                      className=''
                      to={{
                        pathname: `/project-info-hot-sheet/${projectId}`,
                        state: {
                          projectData: projectData,
                          objectId: projectId,
                        },
                      }}
                      data-tooltip-content={t('MENU4.SUBMENUS.SUBMENU6.Tooltip')}
                      data-tooltip-id='MENU4.SUBMENUS.SUBMENU6.Tooltip'
                    >
                      {t('Project Info Hot Sheet')}
                    </Link>
                    <Tooltip
                      id='MENU4.SUBMENUS.SUBMENU6.Tooltip'
                      place='right'
                      delayShow={1000}
                    />
                  </li>
                )}
              </>
            )}
            {isAllowed(reportAccess.PHR, 'assigned', userAssignData) && (
              <li className='dropdown-item'>
                <Link
                  className=''
                  to={{
                    pathname: `/project-history-report/${projectId}`,
                    state: {
                      projectData: projectData,
                      objectId: projectId,
                    },
                  }}
                  data-tooltip-content={t('MENU4.SUBMENUS.SUBMENU8.Tooltip')}
                  data-tooltip-id='MENU4.SUBMENUS.SUBMENU8.Tooltip'
                >
                  {t('Project History Report')}
                </Link>
                <Tooltip
                  id='MENU4.SUBMENUS.SUBMENU8.Tooltip'
                  place='right'
                  delayShow={1000}
                />
              </li>
            )}

            {isAllowed(reportAccess.PHR, 'assigned', userAssignData) &&
              !isServiceProject && (
                <li className='dropdown-item'>
                  <Link
                    className=''
                    to={{
                      pathname: `/bid-history-report/${projectId}`,
                      state: {
                        projectData: projectData,
                        objectId: projectId,
                      },
                    }}
                    data-tooltip-content={t('Summary of all bids on this project')}
                    data-tooltip-id='Bid.History.Tooltip'
                  >
                    {t('Bid History Report')}
                  </Link>
                  <Tooltip id='Bid.History.Tooltip' place='right' delayShow={1000} />
                </li>
              )}

            <div className='dropdown-divider'></div>
            <li className='dropdown-item' data-tooltip-content={t('Hide empty project fields from details page')}
              data-tooltip-id='HideEmptyFields.Tooltip'>

              
              <Checkbox
                label='Hide empty fields'
                name='hideEmptyFields'
                checked={hideEmptyFields}
                onChange={hideEmptyFieldsToggle}
              />
              <label className='ms-2' onClick={hideEmptyFieldsToggle}>
                Hide empty fields
              </label>


            </li>
            <Tooltip id='HideEmptyFields.Tooltip' place='right' delayShow={1000} />

            <div className='dropdown-divider'></div>
            <li className='dropdown-item'>
              <ReactToPrint
                trigger={() => <a>Print project page</a>}
                content={() => componentToPrint.current}
              />
            </li>
            {true && (
              <>
                <div className='dropdown-divider'></div>
                <li onClick={toggleFillTemplate.bind(this, true)} data-tooltip-content={t('Upload a word template to auto-fill with project details')}
                  data-tooltip-id='FillTemplate.Tooltip'>

                  <a
                    className='dropdown-item'

                  >
                    Fill template
                  </a>
                </li>
                <Tooltip id='FillTemplate.Tooltip' place='right' delayShow={1000} />
              </>
            )}
            {(isUndefinedProjectStatus || hasOverridePermissions) && (
              <li>
                <div className='dropdown-divider'></div>
                <a
                  className='dropdown-item text-danger'
                  onClick={toggleStatusUpdate.bind(this, 'OverrideStatus')}
                >
                  Override status
                </a>
              </li>
            )}
          </ul>
        </li>
      )}</>
    )
  };

  const renderUpdateStatusBiddingMenu = () => {
    const statusButtons = [];

    if (isBiddingProject && isAllowed(
      moduleConstants.BP,
      'assigned',
      projectData?.userAssignData
    )) {
      if (projectHasValidBid) {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
          'Mark bid as submitted and move status to pending'
        )}
          data-tooltip-id='MarkBidSubmitted.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'Submitted')}> <a
            className='statusBtn'

          >
            {t('Mark as Submitted')}
          </a><Tooltip
            id='MarkBidSubmitted.ToolTip'
            place='right'
            delayShow={100}
          /></li>);
      } else {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
          'Please enter bid information before submitting bid'
        )}
          data-tooltip-id='MarkBidSubmitted.ToolTip'
          onClick={handleBidManagementModalToggle.bind(
            this,
            'createEdit'
          )}> <a
            className='statusBtn'

            disabled

          >
            {t('Mark as Submitted')}
          </a><Tooltip
            id='MarkBidSubmitted.ToolTip'
            place='right'
            delayShow={100}
          /></li>);
      }

      if (projectHasValidBid) {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
          'Directly promote bid to awarded or next phase'
        )}
          data-tooltip-id='MarkApproved.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'DirectPromote')}>
          <a
            className='statusBtn'

          >
            {t('Mark as Selected/Awarded')}
          </a> <Tooltip
            id='MarkApproved.ToolTip'
            place='right'
            delayShow={100}
          />
        </li>)
      }

      if (projectHasValidBid) {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t('Directly mark bid as submitted & lost')}
          data-tooltip-id='MarkBidLost.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'DirectLost')}>
          <a
            className='statusBtn'
          >
            {t('Mark as Lost')}
          </a>  <Tooltip
            id='MarkBidLost.ToolTip'
            place='right'
            delayShow={100}
          />
        </li>)
      }

      if (projectHasValidBid) {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t('Directly close this bid and open a new bid with a new bidding schedule')}
          data-tooltip-id='MarkRebid.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'DirectRebid')}>
          <a
            className='statusBtn'
          >
            {t('Mark as Rebid/Reschedule')}
          </a>  <Tooltip
            id='MarkRebid.ToolTip'
            place='right'
            delayShow={100}
          />
        </li>)
      }
    }


    if (isBidPendingProject) {
      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
        'Mark Bid/Estimate as approved and move to next phase'
      )}
        data-tooltip-id='MarkApproved.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'Approved')}>
        <a
          className='statusBtn'

        >
          {t('Mark as Selected/Awarded')}
        </a>
        <Tooltip id='MarkApproved.ToolTip' place='right' delayShow={1000} />
      </li>);

      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
        'Mark Bid/Estimate as Rebid and return to the previous phase'
      )}
        data-tooltip-id='MarkRebid.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'Rebid')}>
        <a
          className='statusBtn'

        >
          {t('Mark as Rebid')}
        </a>
        <Tooltip id='MarkRebid.ToolTip' place='right' delayShow={1000} />
      </li>);

      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
        'Mark Bid/Estimate as Lost and close the project'
      )}
        data-tooltip-id='MarkLost.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'Lost')}>
        <a
          className='statusBtn'

        >
          {t('Mark as Lost')}
        </a>
        <Tooltip id='MarkLost.ToolTip' place='right' delayShow={1000} />
      </li>);

      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
        'Mark received as Rescinded and return to the previous phase or choose to close the project'
      )}
        data-tooltip-id='MarkRescinded.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'Rescinded')}>
        <a
          className='statusBtn'

        >
          {t('Mark as Rescinded')}
        </a>
        <Tooltip id='MarkRescinded.ToolTip' place='right' delayShow={1000} />
      </li>);
    }

    if (isBiddingProject || isBidPendingProject) {
      if (isAllowed(
        moduleConstants.OH,
        'assigned',
        projectData?.userAssignData
      )) {
        statusButtons.push(<li className='dropdown-item' data-tooltip-content={t('Mark current bid as On Hold')}
          data-tooltip-id='MarkOnHold.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'On Hold')}>
          <a
            className='statusBtn'

          >
            {t('Mark as On Hold')}
          </a>
          <Tooltip id='MarkOnHold.ToolTip' place='right' delayShow={1000} />
        </li>);
      }
    }

    if (isClosedBiddingProject) {
      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t(
        'Reopen project and move to last Bidding/Estimating state'
      )}
        data-tooltip-id='MarkBidding.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'reopenProject')}>
        <a
          className='statusBtn'

        >
          {t('Reopen Project')}
        </a>
        <Tooltip id='MarkBidding.ToolTip' place='right' delayShow={1000} />
      </li>);
    }

    if (isOnHold) {
      statusButtons.push(<li className='dropdown-item' data-tooltip-content={t('Move On Hold to Bidding/Estimating')}
        data-tooltip-id='MarkBidding.ToolTip'
        onClick={toggleStatusUpdate.bind(this, 'ResumeFromHold')}>
        <a
          className='statusBtn'

        >
          {t('Resume Bid/Estimate')}
        </a>
        <Tooltip id='MarkBidding.ToolTip' place='right' delayShow={1000} />
      </li>);
    }

    if (isAllowed(moduleConstants.NC, 'assigned', projectData?.userAssignData) &&
      (!isOnHoldPending && !isBidPendingProject && (isBiddingProject || isOnHoldBidding))) {
      statusButtons.push(
        <li className='dropdown-item' data-tooltip-content={t('Mark bid as Not Bid and Close Bidding')}
          data-tooltip-id='MarkNotBid.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'Closed')}>
          <a
            className='statusBtn'

          >
            {t('Mark as Not-Bid')}
          </a>
          <Tooltip id='MarkNotBid.ToolTip' place='right' delayShow={1000} />
        </li>
      );
    }

    return (
      <>{statusButtons.length > 0 && (<li className='dropdown px-2'>
        <a
          className='dropdown-toggle btn'
          data-bs-toggle='dropdown'
          href='#'
          role='button'
          aria-expanded='false'
        >
          Update Status
        </a>
        <ul className='dropdown-menu  ml-auto'>
          {statusButtons.map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
        </ul>
      </li>)}</>
    );
  }

  const renderUpdateStatusMenu = () => {

    const statusButtons = [];

    if (isActiveProject) {
      if (isAllowed(
        moduleConstants.W,
        'assigned',
        projectData?.userAssignData)) {
        statusButtons.push(
          <li className='dropdown-item'>
            <a
              className='statusBtn'
              data-tooltip-content={t(
                'Mark project as Delivered and begin closeout period'
              )}
              data-tooltip-id='MarkDelivered.ToolTip'
              onClick={toggleStatusUpdate.bind(this, 'Delivered')}
            >
              {t('Delivered')}
            </a>
            <Tooltip id='MarkDelivered.ToolTip' place='right' delayShow={1000} />
          </li>
        );
      }

      statusButtons.push(
        <li className='dropdown-item'>
          <a
            className='statusBtn'
            data-tooltip-content={t(
              'Mark project as Sold Not Started to indicate that it is still awaiting notice to proceed (NTP) form the owner'
            )}
            data-tooltip-id='MarkProjSNS.ToolTip'
            onClick={toggleStatusUpdate.bind(this, 'SNS')}
          >
            {t('Awaiting NTP')}
          </a>
          <Tooltip id='MarkProjSNS.ToolTip' place='right' delayShow={1000} />
        </li>
      );
    }

    if (isSoldNotStartedProject) {

      statusButtons.push(
        <li className='dropdown-item'>
          <a
            className='statusBtn'
            data-tooltip-content={t('Mark Unstarted Project as Active')}
            data-tooltip-id='MarkActive.ToolTip'
            onClick={toggleStatusUpdate.bind(this, 'ProceedToActive')}
          >
            {t('Active')}
          </a>
          <Tooltip id='MarkActive.ToolTip' place='right' delayShow={1000} />
        </li>
      );
    }

    if (isActiveProject || isSoldNotStartedProject) {

      if (isAllowed(
        moduleConstants.SUSP,
        'assigned',
        projectData?.userAssignData
      )) {
        statusButtons.push(
          <li className='dropdown-item'>
            <a
              className='statusBtn'
              data-tooltip-content={t('Suspend project to indicate that it is on hold')}
              data-tooltip-id='MarkSuspended.ToolTip'
              onClick={toggleStatusUpdate.bind(this, 'SUSP')}
            >
              {t('Suspend')}
            </a>
            <Tooltip id='MarkSuspended.ToolTip' place='right' delayShow={1000} />
          </li>
        );
      }
    }

    if (isActiveProject || isDeliveredProject) {
      if (isAllowed(
        moduleConstants.W,
        'assigned',
        projectData?.userAssignData
      )) {
        statusButtons.push(
          <li className='dropdown-item'>
            <a
              className='statusBtn'
              data-tooltip-content={t('Mark project as Delivered and Closed out')}
              data-tooltip-id='MarkCloseout.ToolTip'
              onClick={toggleStatusUpdate.bind(this, 'Closeout')}
            >
              {t('Close out')}
            </a>
            <Tooltip id='MarkCloseout.ToolTip' place='right' delayShow={1000} />
          </li>
        )
      }
    }

    if (isSuspendedProject) {
      statusButtons.push(<li className='dropdown-item'>
        <a
          className='statusBtn'
          data-tooltip-content={t('Resume Suspended project')}
          data-tooltip-id='MarkUnsuspend.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'Unsuspend')}
        >
          {t('Unsuspend')}
        </a>
        <Tooltip id='MarkUnsuspend.ToolTip' place='right' delayShow={1000} />
      </li>);

      statusButtons.push(<li className='dropdown-item'>
        <a
          className='statusBtn'
          data-tooltip-content={t('Mark project as terminated')}
          data-tooltip-id='MarkTerminated.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'Terminated')}
        >
          {t('Mark as Terminated')}
        </a>
        <Tooltip id='MarkTerminated.ToolTip' place='right' delayShow={1000} />
      </li>)
    }

    if (isServiceProject && !isServiceComplete) {
      if (!isWarrantyService && !isServiceDelivered) {
        statusButtons.push(<li className='dropdown-item'>
          <a
            className='statusBtn'
            data-tooltip-content={t(
              'Mark Service project as Delivered'
            )}
            data-tooltip-id='MarkWarranty.ToolTip'
            onClick={toggleStatusUpdate.bind(this, 'Delivered')}
          >
            {t('Delivered')}
          </a>
          <Tooltip id='MarkWarranty.ToolTip' place='right' delayShow={1000} />
        </li>);
      }

      statusButtons.push(<li className='dropdown-item'>
        <a
          className='statusBtn'
          data-tooltip-content={t(
            'Mark Service project as Closed Out and place in Service Complete status'
          )}
          data-tooltip-id='MarkCloseout.ToolTip'
          onClick={toggleStatusUpdate.bind(this, 'Closeout')}
        >
          {t('Close Out')}
        </a>
        <Tooltip id='MarkCloseout.ToolTip' place='right' delayShow={1000} />
      </li>);
    }

    return (
      <>{statusButtons?.length > 0 && (<li className='dropdown px-2'>
        <a
          className='dropdown-toggle btn'
          data-bs-toggle='dropdown'
          href='#'
          role='button'
          aria-expanded='false'
        >
          Update Status
        </a>
        <ul className='dropdown-menu  ml-auto'>
          {statusButtons.map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
        </ul>
      </li>)}</>
    );

  }

  return (
    <Suspense fallback='loading'>
      <TitleComponent
        data={{
          title: `${projectData && projectData.jobName ? projectData.jobName : 'NA'} - Project Details`
        }}
      />
      <div className='row mb-3 mb-md-0'>
        <div className='col-md-6 mb-3 mb-md-0'>
          <div className='d-flex'>
            <div className='p-1'>
              <div className='heading2 mb-1'>{projectData?.jobName || 'NA'}</div>
              <div className='profileSubInfo d-flex flex-wrap'>
                <div className='p-1'>
                  <strong>{projectData?.serviceType === 'Service' ? 'Service# ' : 'Job# '}</strong>
                  {projectData?.jobNumFormatted}
                </div>
                {accountInfo.alternateJobNumber && (
                  <div className='p-1'>
                    <strong>•&nbsp;Alt Job#&nbsp;</strong>
                    {projectData?.altJobNum || 'NA'}
                  </div>
                )}
                <div className='profileStatus p-1'>
                  <strong>•&nbsp;Status:&nbsp;</strong>
                  {currentJobStatusName || 'NA'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='d-flex flex-wrap mt-md-3 mt-sm-0 justify-content-end gap-2 detailMenu'>
            {renderActiveFinanceButtons()}
            {renderManageBid()}
            {
              // Service Menu
              isServiceProject && !isWarrantyService && (
                <button className='btn btn-primary' onClick={toggleManageContract}>
                  Manage Original Contract
                </button>
              )
            }
            {canUpdateStatus &&
              renderUpdateStatusMenu()}

            {canUpdateStatus &&
              renderUpdateStatusBiddingMenu()}

            {canEditDetails && (
              <button className='btn btn-primary' onClick={() => onEditProjectClick('')}>
                Edit project details
              </button>
            )}
            {renderOverflowMenu()}
          </div>
        </div>
      </div>

      {projectDataLoaded && <>

        <ProjectStatusModal
          projectId={projectId}
          projectData={projectData}
          projectStatus={isStatusUpdateModalOpen}
          initialUpdateType={statusUpdateType}
          toggle={toggleStatusUpdate}
          onNext={onNextProjectStatusModalClick}
          onDiscardStatusUpdate={onDiscardStatusUpdate}
          statusUpdateData={statusUpdateData}
          childRef={ref => (projectStatusModalRef.current = ref)}
          onAfterPromoteToBidding={onAfterPromoteToBidding}
          onAfterSubmit={onEditProjectModalSubmit}
        />

        <BidModal
          isOpen={isBidManagementModalOpen}
          toggle={handleBidManagementModalToggle}
          toggleStatusUpdate={toggleStatusUpdate}
          onSubmit={onBidManagementModalSubmit}
          showSaveSubmit={!isEditProjectModalOpen}
          projectId={projectId}
        />

        <ChangeOrderIntermediateModal
          isOpen={isChangeOrderIntermediateModalOpen}
          toggle={handleChangeOrderIntermediateModalToggle}
          project={projectData}
          initialChangeOrderId={currentChangeOrderId}
          //onSuggestProgress={onSuggestProgress}
        />

        <ManageContractModal
          projectId={projectId}
          isOpen={isManageContractModalOpen}
          toggle={toggleManageContract}
          onSubmit={onSubmitManageContract}
          onSuggestProgress={onSuggestProgress}
        />

        <ChangeOrderModal
          isOpen={isChangeOrderModalOpen}
          toggle={handleChangeOrderModalToggle}
          onSubmit={onEditProjectModalSubmit}
          project={projectData}
          coId={currentChangeOrderId}
        />

        <ProgressSuggestionsModal
          isOpen={isSuggestionsModalOpen}
          toggle={handleSuggestionsModalToggle}
          changes={suggestedChanges}
          onUpdateProgress={onUpdateWIPModal}
        />

        <AddProjectModal
          open={editProjectModalOpen}
          onClose={onEditProjectModalClose}
          onManageBid={(viewType) => handleBidManagementModalToggle(viewType)}
          onSubmit={onEditProjectModalSubmit}
          projectId={projectId}
          initialSection={initialSection}
          ref={addProjectModalRef}
        />

        <SummaryFinancial
          isOpen={isFinancialsModalOpen}
          toggle={toggleSummaryFinancials}
          projectId={projectId}
        />

        {fillTemplateOpen && projectData && (
          <FillTemplateModal
            open={fillTemplateOpen}
            toggle={() => toggleFillTemplate(true)}
            objectId={projectId}
          ></FillTemplateModal>
        )}

      </>}

    </Suspense>
  );
});


export default ProjectDetailToolbar;
